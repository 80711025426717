/*@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*/
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@3.0.1/dist/cookieconsent.css");
@import url("https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@3.0.1/dist/cookieconsent.umd.js");

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import 'aos/dist/aos.css'; 

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .delay-0 {
        animation-delay: 0s;
    }
    .delay-1 {
        animation-delay: 1s;
    }
    .delay-2 {
        animation-delay: 2s;
    }
    .delay-3 {
        animation-delay: 3s;
    }
    .delay-4 {
        animation-delay: 4s;
    }
    .delay-5 {
        animation-delay: 5s;
    }
}
